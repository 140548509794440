import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import { H2 } from "../components/Heading"
import BodyText from "../components/BodyText"
import { LAYOUT, EMAIL_ADDRESSES } from "../constants"
import contactEnvelopeImage from "../assets/img/contactUs/envelope.png"
import Obfuscate from "react-obfuscate"

const CONTACT_DATA = [
  { heading: "General Enquiries", email: EMAIL_ADDRESSES.GENERAL },
  {
    heading: "Volunteering & Recruitment",
    email: EMAIL_ADDRESSES.VOLUNTEERING_RECRUITMENT,
  },
  {
    heading: "Corporate & Charity Partnerships",
    email: EMAIL_ADDRESSES.CORPORATE_CHARITY_PARTNERSHIPS,
  },
  {
    heading: "Design & Marketing",
    email: EMAIL_ADDRESSES.DESIGN_MARKETING,
  },
  {
    heading: "Press & PR",
    email: EMAIL_ADDRESSES.PRESS_PR,
  },
  {
    heading: "Social Media",
    email: EMAIL_ADDRESSES.SOCIAL_MEDIA,
  },
  {
    heading: "Your privacy questions/concerns",
    email: EMAIL_ADDRESSES.PRIVACY,
  },
]

const Root = styled.div`
  max-width: 906px;
  padding: 80px 0;
  margin: 0 auto;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  }
`

const EnvelopeImage = styled.img`
  max-width: 455px;
  width: 100%;
  align-self: flex-start;
`

const Heading = styled(H2)`
  margin-bottom: 10px;
`

const EmailRow = styled.div`
  margin-bottom: 60px;
`

const ContactInfoContainer = styled.div``
interface IContactDetails {
  rest?: object
}

const ContactDetails: React.FC<IContactDetails> = ({ ...rest }) => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      envelope: file(relativePath: { eq: "contactUs/envelope.png" }) {
        childImageSharp {
          fluid(maxWidth: 455, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Root {...rest}>
      <ContactInfoContainer>
        {CONTACT_DATA.map(data => (
          <EmailRow>
            <Heading>{data.heading}</Heading>
            <BodyText type={"DARK"}>
              <Obfuscate email={data.email} />
            </BodyText>
          </EmailRow>
        ))}
      </ContactInfoContainer>
      <EnvelopeImage src={contactEnvelopeImage} alt={"Envelope"} />
    </Root>
  )
}

export default ContactDetails
