import React from "react"
import { Helmet } from "react-helmet"

import Nav from "../partials/Nav"
import PageSection from "../layout/PageSection"
import SubPageHero from "../partials/SubPageHero"
import ContactDetails from "../partials/ContactDetails"
import Footer from "../partials/Footer"
import CookieBanner from "../partials/CookieBanner"
import "../styles.css"

const ContactUs: React.FC<{}> = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us | Have a question? | Run For Heroes</title>
        <meta
          name="description"
          content="Have a question for us? Interested in working for us? Want to know more about our next event? Send us an email and we'll get back to you."
        />
        <html lang="en" />
      </Helmet>
      <Nav activePath={"/contact-us"} />
      <PageSection backgroundColor={"BLUE"}>
        <SubPageHero
          heading={"Contact us"}
          subHeading={
            "Whether you’d like to work for us, partner with us or just have a general enquiry, we always love hearing from you."
          }
        />
      </PageSection>
      <PageSection backgroundColor={"MUTED_GREY"}>
        <ContactDetails />
      </PageSection>
      <PageSection backgroundColor={"GREY"}>
        <Footer />
      </PageSection>
      <CookieBanner />
    </>
  )
}

export default ContactUs
